import { Image, type ImageProps } from '@/presenters/components/common/Image';

export interface ImageWithFallbackProps extends ImageProps {
  fallbackSrc?: string;
}

function ImageWithFallback(props: Readonly<ImageWithFallbackProps>) {
  const { fallbackSrc, ...image } = props;

  if (fallbackSrc) {
    return (
      <picture data-testid="picture-element">
        <source data-testid="source-element" srcSet={fallbackSrc} />
        <Image {...image} alt={image.alt} />
      </picture>
    );
  }

  return <Image {...image} alt={image.alt} />;
}

export default ImageWithFallback;
